var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"270px"}},[(_vm.loader == true)?_c('div',{staticClass:"loaderDiv d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e(),(_vm.loader == false)?_c('div',{staticClass:"card-body",staticStyle:{"position":"relative"}},[_c('l-map',{staticStyle:{"height":"260px","width":"100%"},attrs:{"zoom":_vm.marker.position ? 7 : _vm.mapZoom,"min-zoom":4,"center":_vm.marker.position ? _vm.marker.position : _vm.mapCenter}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"subdomains":['mt0', 'mt1', 'mt2', 'mt3']}}),(_vm.marker.position)?_c('l-marker',{attrs:{"zIndexOffset":100,"lat-lng":_vm.marker.position,"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor}},[_c('img',{attrs:{"src":_vm.imageDeviceGreen,"width":"40"}})]),_c('l-tooltip',{staticClass:"rounded",attrs:{"options":{
            interactive: 'true',
            direction: 'top',
          }}},[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.name.text"))+" : ")]),_vm._v(" "+_vm._s(_vm.marker.name)),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.stockType.text"))+" : ")]),_c('span',{staticClass:"badge font-size-12",class:{
              'badge-danger':
                `${_vm.marker.stockType}` === '24H' ||
                `${_vm.marker.stockType}` === '24 H' ||
                `${_vm.marker.stockType}` === '24 H ' ||
                `${_vm.marker.stockType}` === '24H ',
              'badge-warning':
                `${_vm.marker.stockType}` === 'DEMI JOURNEE' ||
                `${_vm.marker.stockType}` === 'jusqu\'à 20H' ||
                `${_vm.marker.stockType}` === 'Jusqu\'à 20h',
              'badge-success':
                `${_vm.marker.stockType}` === 'VIDE' ||
                `${_vm.marker.stockType}` === 'VIDE',
              'badge-secondary':
                `${_vm.marker.stockType}` === '-' ||
                `${_vm.marker.stockType}` === 'NON COMMUNIQUE',
            }},[_vm._v(" "+_vm._s(_vm.marker.stockType)+" ")]),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Province : ")]),_vm._v(" "+_vm._s(_vm.marker.province.name)+" ")])],1):_vm._e(),(_vm.markerStart.position)?_c('l-marker',{attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":_vm.markerStart.position,"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor}},[_c('img',{attrs:{"src":_vm.imageStart,"width":"40"}})])],1):_vm._e(),_c('l-polyline',{attrs:{"lat-lngs":_vm.positionsData,"color":"#FF2424"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }