<script>
/**
 * Device Infos component
 */
//import moment from "moment"
import moment from "moment-timezone";
import { devicesComputed, devicesMethods } from "@/state/helpers";
import RouteDevice from "./position";
export default {
  props: ["device", "events", "loader"],
  data() {
    return {
      deviceInfos: {},
      eventsData: [],
      alertsList: [],
      lastTemp: null,
      lastTempDate: null,
      chartOptions: {
        chart: {
          type: "line",
          height: 250,
          zoomType: "x",
        },
        plotOptions: {
          series: {
            turboThreshold: 100000,
            animation: false,
          },
        },
        scrollbar: {
          liveRedraw: false,
        },
        xAxis: {
          type: "datetime",
          zoomEnabled: true,
          /* labels: {
            format: "{value:%B %d}",
        }, */
          minRange: 3600 * 1000,
        },
        yAxis: {
          title: {
            text: this.$t("charts.temperature.text") + " (°C)",
          },
          opposite: false,
        },

        tooltip: {
          xDateFormat: "%Y/%m/%d, %H:%M:%S",
          valueDecimals: 2,
          valueSuffix: " °C",
        },
        lang: {
          noData: this.$t("charts.noData.text"),
        },

        title: {
          text: this.$t("charts.temperature.text"),
        },
        rangeSelector: {
          buttons: [
            {
              type: "hour",
              count: 1,
              text: "1h",
            },
            {
              type: "day",
              count: 1,
              text: "1d",
            },
            {
              type: "month",
              count: 1,
              text: "1m",
            },
            {
              type: "year",
              count: 1,
              text: "1y",
            },
            {
              type: "all",
              text: "All",
            },
          ],
          inputEnabled: false, // it supports only days
          selected: 5, // all
        },
        exporting: {
          enabled: true,
        },
        series: [],
      },
    };
  },
  components: {
    RouteDevice,
  },
  computed: {
    ...devicesComputed,
    fieldsComputed() {
      return [
        {
          key: "date",
          sortable: false,
          label: this.$t("dataTable.dateTime.text"),
        },
        {
          key: "state",
          sortable: false,
          label: this.$t("dataTable.status.text"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("dataTable.value.text"),
        },
      ];
    },
    avgTmp() {
      let data = [];
      this.events.map((obj) => {
        if (obj.temp !== 127) {
          data.push(obj);
        }
      });

      const average = (args) => args.reduce((a, b) => a + b) / args.length;
      let arrayEvents = data.map((obj) => {
        return obj.temp;
      });
      if (arrayEvents.length > 0) {
        return average(arrayEvents);
      } else {
        return 0;
      }
    },
    maxTmp() {
      let arrayEvents = [];
      this.events.map((obj) => {
        if (obj.temp !== 127) {
          arrayEvents.push(obj);
        }
      });
      let max = arrayEvents[0];
      arrayEvents.map((obj) => {
        if (obj.temp >= max.temp) {
          max = obj;
        }
      });
      return max;
    },
    minTmp() {
      let arrayEvents = [];
      this.events.map((obj) => {
        if (obj.temp !== 127) {
          arrayEvents.push(obj);
        }
      });
      let min = arrayEvents[0];
      arrayEvents.map((obj) => {
        if (obj.temp <= min.temp) {
          min = obj;
        }
      });
      return min;
    },
  },
  mounted() {},
  methods: {
    ...devicesMethods,
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toastClass: "toasterClass",
      });
    },
    closeCard() {
      let card = this.$refs["deviceInfosCard"];
      /* card.style.transform = "translate(970px, 0px)";
          card.style.transition = ".5s"; */
      card.style.visibility = "hidden";
    },
  },
  watch: {
    device(newVal) {
      this.deviceInfos = newVal;
    },
    events(newVal) {
      this.eventsData = newVal;
      let data = [...newVal];
      
      let avg = this.avgTmp;
      let max = this.maxTmp;
      let min = this.minTmp;
      let tempName = this.$t("charts.temperature.text");
      let tempAvg = this.$t("charts.temperatureAvg.text");
      let tempMax = this.$t("charts.temperatureMax.text");
      let tempMin = this.$t("charts.temperatureMin.text");

      let eventsData = [];
      this.lastTemp = null;
      this.lastTempDate = null;

      if (data.length > 0) {
        this.lastTemp = data[0].temp;
        this.lastTempDate = moment(data[0].createdAt).tz('Africa/Casablanca').format(
          "DD-MM-YYYY HH:mm:ss"
        );
        //data.reverse();
        for (let item in data) {
          var date = new Date(data[item].createdAt).getTime() + 60 * 60 * 1000;
          if (data[item].temp !== 127)
            eventsData[item] = { x: date, y: data[item].temp };
        }

        this.chartOptions.series = [
          {
            name: tempName,
            data: eventsData,
            marker: {
              enabled: false,
            },
          },
          {
            id: "maxSeries",
            name: tempMax,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.point.label;
              },
            },
            color: "rgb(230, 0, 0)",
            data: [
              {
                x: new Date(max.createdAt).getTime() + 60 * 60 * 1000,
                y: max.temp,
                label: max.temp + " °C",
              },
            ],
            marker: {
              fillColor: "rgb(230, 0, 0)",
              symbol: "circle",
              radius: 5,
            },
          },
          {
            id: "minSeries",
            name: tempMin,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.point.label;
              },
            },
            color: "rgb(0, 230, 0)",
            data: [
              {
                x: new Date(min.createdAt).getTime() + 60 * 60 * 1000,
                y: min.temp,
                label: min.temp + " °C",
              },
            ],
            marker: {
              fillColor: "rgb(0, 230, 0)",
              symbol: "circle",
              radius: 5,
            },
          },
        ];
        this.chartOptions.yAxis.plotLines = [
          {
            color: "red",
            label: {
              formatter: function () {
                return `${tempAvg} ${avg.toFixed(2)}  (°C)`;
              },
              style: {
                fontSize: 13,
                fontWeight: "bold",
              },
            },
            width: 2,
            zIndex: 10,
            value: avg,
          },
        ];
      } else {
        this.chartOptions.series = [
          {
            name: tempName,
            data: eventsData,
          },
        ];
      }
    },
    alerts(newVal) {
      let data = newVal.map((obj) => {
        return {
          state: obj.state ? obj.state.name : null,
          date: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
          value: obj.value,
        };
      });
      this.alertsList = data;
    },
  },
};
</script>

<template>
  <div id="deviceInfosCard" ref="deviceInfosCard" style="">
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div class="row">
      <div class="col-12 pb-0 text-right">
        <b-button type="button" variant="dark" size="sm" @click="closeCard()"
          ><i class="ri-close-line text-white"></i>
        </b-button>
      </div>
    </div>
    <div class="row" v-if="loader == false">
      <div class="col-12 col-lg-4">
        <div class="card m-0" style="min-height: 342px">
          <div class="card-header bg-dark text-white">
            <img
              class="mr-2"
              :src="require('@/assets/images/temp_w.svg')"
              width="10"
            />{{ $t("modals.lastTemp.text") }}
          </div>
          <div class="row mt-2">
            <div class="col-2 text-center">
              <img width="30" :src="require('@/assets/images/device.svg')" />
            </div>
            <div class="col-7" style="font-size: 17px">
              <b>Serial : </b> {{ deviceInfos.serial }}<br />
              <b>{{ $t("modals.name.text") }} :</b> {{ deviceInfos.name }}
            </div>
            <div class="col-3">
              <p
                v-if="deviceInfos.stockType"
                class="badge font-size-12"
                :class="{
                  'badge-danger':
                    `${deviceInfos.stockType}` === '24H' ||
                    `${deviceInfos.stockType}` === '24 H' ||
                    `${deviceInfos.stockType}` === '24 H ' ||
                    `${deviceInfos.stockType}` === '24H ',
                  'badge-warning':
                    `${deviceInfos.stockType}` === 'DEMI JOURNEE' ||
                    `${deviceInfos.stockType}` === 'jusqu\'à 20H' ||
                    `${deviceInfos.stockType}` === 'Jusqu\'à 20h',
                  'badge-success':
                    `${deviceInfos.stockType}` === 'VIDE' ||
                    `${deviceInfos.stockType}` === 'VIDE',
                  'badge-secondary':
                    `${deviceInfos.stockType}` === '-' ||
                    `${deviceInfos.stockType}` === 'NON COMMUNIQUE',
                }"
              >
                {{ deviceInfos.stockType }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-center">
              <img width="30" :src="require('@/assets/images/device.svg')" />
            </div>
            <div
              v-if="deviceInfos.region"
              class="col-10"
              style="font-size: 17px"
            >
              <b>{{ $t("modals.region.text") }} : </b>
              {{ deviceInfos.region.name }}<br />
              <b>Province : </b> {{ deviceInfos.province.name }}
            </div>
          </div>
          <div class="row">
            <div v-if="lastTemp" class="col-12 text-center">
              <h1 style="font-size: 50px; margin-bottom: 0px">
                {{ lastTemp }} °C
              </h1>
              <p style="font-size: 15px">{{ lastTempDate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card m-0" style="min-height: 342px">
          <div class="card-header bg-dark text-white">
            <img
              class="mr-2"
              :src="require('@/assets/images/evo_w.svg')"
              width="18"
            />{{ $t("modals.tempEvolution.text") }}
          </div>
          <highcharts :options="chartOptions"></highcharts>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card m-0" style="min-height: 342px">
          <div class="card-header bg-dark text-white">
            <img
              class="mr-2"
              :src="require('@/assets/images/alert_w.svg')"
              width="18"
            />Position
          </div>
          <RouteDevice
            v-bind:device="deviceInfos"
            :events="eventsData"
            :loader="loader"
          />
        </div>
      </div>
    </div>
    <div class="row" v-if="loader == false">
      <div class="col-12 text-center">
        <b-button
          variant="danger"
          @click="
            $router.push({
              name: 'device',
              params: { serial: deviceInfos.serial },
            })
          "
          >{{ $t("buttons.moreInfos.text") }}</b-button
        >
      </div>
    </div>
  </div>
  <!-- <div id="deviceInfosCard" ref="deviceInfosCard" class="card" style="">
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div
      v-if="loader == false"
      class="card-header bg-dark-gray"
      style="position: relative"
    >
      <div class="row">
        <div class="col-10">
          <h4 class="text-white" v-if="deviceInfos.name">
            {{ deviceInfos.name }}
          </h4>
          <h3 class="text-white" v-else>...</h3>
        </div>
        <div class="col-2 text-right">
          <b-button
            type="button"
            variant="transparent"
            size="lg"
            @click="closeCard()"
            ><i class="ri-close-line text-white"></i>
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="loader == false" class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <highcharts :options="chartOptions"></highcharts>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-9">
          <table style="width: 100%">
            <tr>
              <td class="font-weight-bold">Serial :</td>
              <td v-if="deviceInfos.serial">
                {{ deviceInfos.serial }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t("modals.name.text") }} :</td>
              <td v-if="deviceInfos.name">{{ deviceInfos.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t("modals.stockType.text") }} :
              </td>
              <td
                v-if="deviceInfos.stockType"
                class="badge font-size-12"
                :class="{
                  'badge-danger':
                    `${deviceInfos.stockType}` === '24H' ||
                    `${deviceInfos.stockType}` === '24 H' ||
                    `${deviceInfos.stockType}` === '24 H ' ||
                    `${deviceInfos.stockType}` === '24H ',
                  'badge-warning':
                    `${deviceInfos.stockType}` === 'DEMI JOURNEE' ||
                    `${deviceInfos.stockType}` === 'jusqu\'à 20H' ||
                    `${deviceInfos.stockType}` === 'Jusqu\'à 20h',
                  'badge-success':
                    `${deviceInfos.stockType}` === 'VIDE' ||
                    `${deviceInfos.stockType}` === 'VIDE',
                  'badge-secondary':
                    `${deviceInfos.stockType}` === '-' ||
                    `${deviceInfos.stockType}` === 'NON COMMUNIQUE',
                }"
              >
                {{ deviceInfos.stockType }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t("modals.region.text") }} :</td>
              <td v-if="deviceInfos.region">
                {{ deviceInfos.region.name }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Province :</td>
              <td v-if="deviceInfos.province">
                {{ deviceInfos.province.name }}
              </td>
            </tr>
          </table>
        </div>
        <div class="col-3 mb-2">
          <b-button
            variant="primary"
            @click="
              $router.push({
                name: 'device',
                params: { id: deviceInfos.id },
              })
            "
            >{{ $t("buttons.moreInfos.text") }}</b-button
          >
        </div>
      </div>
    </div>
  </div> -->
</template>
<style scoped>
th {
  padding: 10px;
  font-size: 20px;
  background-color: #f1f1f1;
  /*border-bottom: 2px solid rgba(44, 44, 44, 0.945) !important;*/
}
td {
  padding: 10px;
}
.edit-btn {
  position: absolute;
  top: 60px;
  right: 40px;
  z-index: 9999;
  background-color: #911f27;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* box-shadow: 0 0 0 2px #a8a6a6; */
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}
.valid-edit-btn {
  position: absolute;
  top: 60px;
  right: 110px;
  z-index: 9999;
  background-color: #3be657;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}
#deviceInfosCard {
  position: fixed;
  width: 90%;
  height: 465px;
  z-index: 10000;
  bottom: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  visibility: hidden;
  overflow-x: hidden;
}
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}
@media (min-width: 992px) {
  #deviceInfosCard {
    overflow-y: hidden;
  }
}
@media (max-width: 991px) {
  #deviceInfosCard {
    overflow-y: scroll;
  }
}
</style>